import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { APIClient } from "../helpers/api_helper";
const apiClient = new APIClient();

const Navdata = () => {
  const history = useNavigate();
  const [userData, setUserData] = useState({});
  //state data
  const [isPrincipal, setIsPrincipal] = useState(false);
  const [isPedidos, setIsPedidos] = useState(false);
  const [isMarketing, setIsMarketing] = useState(false);

  const [iscurrentState, setIscurrentState] = useState("Principal");

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Principal") {
      setIsPrincipal(false);
    }
    if (iscurrentState !== "Pedidos") {
      setIsPedidos(false);
    }
    if (iscurrentState !== "Marketing") {
      setIsMarketing(false);
    }
    setUserData(apiClient.getLoggedinUser());
  }, [history, iscurrentState, isPedidos, isPrincipal, isMarketing]);

  const menuItems = [
    {
      label: "Menu",
      isHeader: true,
    },
    {
      id: "principal",
      label: "Principal",
      icon: "ri-home-line",
      link: "/principal",
      stateVariables: isPrincipal,
      click: function (e) {
        e.preventDefault();
        setIsPrincipal(!isPrincipal);
        setIscurrentState("Principal");
        updateIconSidebar(e);
      },
    },
    {
      id: "pedidos",
      label: "Pedidos",
      icon: "ri-shopping-cart-2-line",
      link: "/pedidos-mercado-pago",
      stateVariables: isPedidos,
      click: function (e) {
        e.preventDefault();
        setIsPedidos(!isPedidos);
        setIscurrentState("Pedidos");
        updateIconSidebar(e);
      },
    },
    {
      id: "marketing",
      label: "Novidades",
      icon: "ri-newspaper-line",
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsMarketing(!isMarketing);
        setIscurrentState("Marketing");
        updateIconSidebar(e);
      },
      stateVariables: isMarketing,
      subItems: [{
          id: "conteudos",
          label: "Buscar Conteúdos",
          link: "/conteudos",
          parentId: "marketing",
        },
        {
          id: "mural-novos-conteudos",
          label: "Novos Conteúdos",
          link: "/mural-novos-conteudos",
          parentId: "marketing",
        },
        {
          id: "mural-jogos",
          label: "Jogos do Dia",
          link: "/mural-jogos",
          parentId: "marketing",
        },
        {
          id: "mural-anuncios-blog",
          label: "Mural de Anúncios",
          link: "/mural-anuncios-blog",
          parentId: "marketing",
        },
      ],
    },
  ];

  return (
    <React.Fragment>
      {userData?.data?.id === 1 ? [...menuItems, ...menuAdmin] : menuItems}
    </React.Fragment>
  );
};
export default Navdata;
