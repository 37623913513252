import React, { useEffect, useState, Fragment, useCallback } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import UiContent from "../../Components/Common/UiContent";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  checkLastLogin,
  limparMensagem,
} from "../../Components/FunctionsRepository";
import ModalAnuncio from "../DashboardEcommerce/ModalAnuncio";
import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

const MuralAnunciosBlog = ({ categoria }) => {
  const tituloPagina =
    categoria === "jogos"
      ? "Jogos do Dia"
      : categoria === "novidades"
      ? "Mural de Anúncios"
      : "Novos Conteúdos";
  document.title = `${tituloPagina} | Dark Store`;

  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadTable, setLoadTable] = useState(true);

  const [anuncios, setAnuncios] = useState([]);
  const [itensPorPagina, setItensPorPagina] = useState(40);
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [totalDeAnuncios, setTotalDeAnuncios] = useState(0);
  const [numeroDePaginas, setNumeroDePaginas] = useState(1);
  const [modal, setModal] = useState(false);
  const [selectedAnuncio, setSelectedAnuncio] = useState(null);

  // Function to toggle modal and set selected announcement
  const toggleModal = (anuncio = null) => {
    setModal(!modal);
    setSelectedAnuncio(anuncio);
  };

  const calcularPaginas = () => {
    const paginas = [];
    const paginasVisiveis = 2;

    // Adicionar as três primeiras páginas
    for (let i = 1; i <= Math.min(paginasVisiveis, numeroDePaginas); i++) {
      paginas.push(i);
    }

    let inicio = Math.max(paginaAtual - paginasVisiveis, paginasVisiveis + 1);
    let fim = Math.min(
      paginaAtual + paginasVisiveis,
      numeroDePaginas - paginasVisiveis
    );

    if (inicio > paginas[paginas.length - 1] + 1) {
      paginas.push("...");
    }

    for (let i = inicio; i <= fim; i++) {
      paginas.push(i);
    }

    if (fim < numeroDePaginas - paginasVisiveis) {
      paginas.push("...");
    }

    // Adicionar as três últimas páginas
    for (
      let i = Math.max(
        numeroDePaginas - paginasVisiveis + 1,
        paginasVisiveis + 1
      );
      i <= numeroDePaginas;
      i++
    ) {
      if (!paginas.includes(i)) {
        paginas.push(i);
      }
    }

    return paginas;
  };

  const converterData = (dataISO) => {
    if (dataISO) {
      const ano = dataISO.substring(0, 4);
      const mes = dataISO.substring(5, 7);
      const dia = dataISO.substring(8, 10);
      const hora = dataISO.substring(11, 13);
      const minuto = dataISO.substring(14, 16);

      return `${dia}/${mes}/${ano} ${hora}:${minuto}`;
    }
    return "";
  };

  const fetchAnuncios = useCallback(
    async (itensPorPagina, paginaAtual, categoria) => {
      setLoading(true);

      const data = {
        itens_pagina: itensPorPagina,
        pagina: paginaAtual,
        categoria: categoria,
      };

      const response = await apiClient.post(`mural-aviso/busca`, data);

      if (response.sucesso) {
        const dados = response.dados;
        setAnuncios(dados.data);
        setNumeroDePaginas(dados.last_page);
        setTotalDeAnuncios(dados.total);
      } else {
        console.error(response.mensagem);
        if (!response.mensagem.includes(" 401")) {
          if (response.mensagem !== "") toast.error(response.mensagem);
        } else {
          await checkLastLogin();
        }
      }
      if (
        !response.sucesso &&
        (!response.responseCode || response.responseCode === 401)
      ) {
        await checkLastLogin();
      }

      setLoading(false);
    },
    []
  );

  useEffect(() => {
    const fetchData = async () => {
      setLoadTable(true);
      await fetchAnuncios(itensPorPagina, paginaAtual, categoria);
      setLoadTable(false);
      setLoading(false);
    };

    fetchData();
  }, [itensPorPagina, paginaAtual, refresh, categoria]);

  return (
    <Fragment>
      <UiContent />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={tituloPagina} pageTitle="Marketing" />
          <Row>
            <Col xl={12}>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">{tituloPagina}</h4>{" "}
                </CardHeader>
                <CardBody>
                  {loading || loadTable ? (
                    <Row className="justify-content-center mt-4 pt-2 text-center">
                      <Spinner />
                    </Row>
                  ) : (
                    <Row className="justify-content-center mt-4 pt-2">
                      {anuncios.map((av, index) => (
                        <Col
                          xxl={3}
                          xl={3}
                          lg={4}
                          md={6}
                          sm={12}
                          key={index}
                          className="mb-3"
                        >
                          <Card
                            onClick={() => toggleModal(av)}
                            style={{ height: "100%" }}
                          >
                            <img
                              className="rounded-start img-fluid h-100 object-fit-cover"
                              src={av.imagem_principal}
                              alt="Imagem Principal"
                              style={{ maxHeight: "180px" }}
                            />
                            <CardBody>
                              <h4 className="card-title mb-2">{av.titulo}</h4>
                              <p className="card-text mb-0">
                                {limparMensagem(av.texto, 114)}{" "}
                                <Link
                                  to="#"
                                  className="card-link link-secondary"
                                >
                                  Leia Mais{" "}
                                  <i className="ri-arrow-right-s-line ms-1 align-middle lh-1"></i>
                                </Link>
                              </p>
                            </CardBody>
                          </Card>
                        </Col>
                      ))}

                      <ModalAnuncio
                        modal={modal}
                        toggleModal={toggleModal}
                        anuncio={selectedAnuncio}
                      />
                    </Row>
                  )}

                  {!loading && !loadTable && anuncios.length === 0 ? (
                    <Row>
                      <small className="text-muted text-center mt-5 mb-5">
                        Nenhum anúncio encontrado
                      </small>
                    </Row>
                  ) : (
                    <div className="align-items-center mt-4 pt-2 justify-content-between row text-center text-sm-start">
                      <div className="col-sm">
                        <div className="text-muted">
                          Mostrando{" "}
                          <span className="fw-semibold">
                            {Math.min(
                              (paginaAtual - 1) * itensPorPagina + 1,
                              totalDeAnuncios
                            )}
                          </span>{" "}
                          a{" "}
                          <span className="fw-semibold">
                            {Math.min(
                              paginaAtual * itensPorPagina,
                              totalDeAnuncios
                            )}
                          </span>{" "}
                          de{" "}
                          <span className="fw-semibold">{totalDeAnuncios}</span>{" "}
                          resultados
                        </div>
                      </div>
                      <div className="col-sm-auto mt-3 mt-sm-0">
                        <ul className="pagination pagination-separated pagination-sm mb-0 justify-content-center">
                          <li
                            className={`page-item ${
                              paginaAtual === 1 ? "disabled" : ""
                            }`}
                            style={{ width: "34px", height: "35px" }}
                          >
                            <Link
                              to="#"
                              className="page-link p-0"
                              onClick={() => {
                                setPaginaAtual(paginaAtual - 1);
                                setLoading(true);
                              }}
                            >
                              <FeatherIcon icon="chevron-left" width="16" />
                            </Link>
                          </li>
                          {calcularPaginas(paginaAtual, numeroDePaginas).map(
                            (pagina, i) =>
                              pagina === "..." ? (
                                <li
                                  key={i}
                                  className="page-item disabled"
                                  style={{ userSelect: "none" }}
                                >
                                  <span className="page-link">{pagina}</span>
                                </li>
                              ) : (
                                <li
                                  key={i}
                                  className={`page-item ${
                                    pagina === paginaAtual ? "active" : ""
                                  }`}
                                  style={{ height: "35px" }}
                                >
                                  <Link
                                    to="#"
                                    className="page-link pb-1 pt-1 pe-2 ps-2"
                                    onClick={() => {
                                      setPaginaAtual(pagina);
                                      setLoading(true);
                                    }}
                                    style={{ fontSize: "0.7rem" }}
                                  >
                                    {pagina}
                                  </Link>
                                </li>
                              )
                          )}
                          <li
                            className={`page-item ${
                              paginaAtual === numeroDePaginas ? "disabled" : ""
                            }`}
                            style={{ width: "34px", height: "35px" }}
                          >
                            <Link
                              to="#"
                              className="page-link p-0"
                              onClick={() => {
                                setPaginaAtual(paginaAtual + 1);
                                setLoading(true);
                              }}
                            >
                              <FeatherIcon icon="chevron-right" width="16" />
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default MuralAnunciosBlog;
