import React, { useState, useCallback, useEffect } from "react";
import {
  Col,
  Card,
  CardBody,
  CardHeader,
  Container,
  Row,
  Button,
} from "reactstrap";
import ModalMercadoPago from "./ModalMercadoPago";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import Section from "./Section";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import hzplay from "../../assets/images/hzplay.png";
import iptv from "../../assets/images/iptv.png";

import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

const DashboardEcommerce = () => {
  document.title = "Dashboard | HzPlay";
  const [userDados, setUserDados] = useState(null);
  const [parentDados, setParentDados] = useState(null);
  const [modalMercadoPago, setModalMercadoPago] = useState(false);

  const converterData = (dataISO) => {
    if (dataISO) {
      const ano = dataISO.substring(0, 4);
      const mes = dataISO.substring(5, 7);
      const dia = dataISO.substring(8, 10);
      const hora = dataISO.substring(11, 13);
      const minuto = dataISO.substring(14, 16);

      return `${dia}/${mes}/${ano} ${hora}:${minuto}`;
    }
    return "";
  };

  const contarDiasRestantes = (dataISO) => {
    if (dataISO) {
      const data = new Date(dataISO);
      const dataAtual = new Date();
      const diferenca = data - dataAtual;
      const dias = Math.floor(diferenca / (1000 * 60 * 60 * 24));
      return dias >= 0 ? dias : 0;
    }
    return 0;
  };

  const getUserDados = useCallback(async (user) => {
    const data = {
      username: user.data.externo_login,
      password: user.data.externo_senha,
    };
    const response = await apiClient.post(
      `painel-clientes/mercado-pago/config`,
      data
    );
    if (response.sucesso) {
      setParentDados(response.dados);
    } else {
      console.error(response.mensagem);
    }
  }, []);

  useEffect(() => {
    const executeFecthData = async () => {
      const userData = JSON.parse(localStorage.getItem("authUser"));
      setUserDados(userData?.data);
      if (userData?.data?.parent_id) await getUserDados(userData);
    };

    if (!userDados) {
      executeFecthData();
    }
  }, [userDados, parentDados]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Principal" pageTitle="Painel" />
          <Row>
            <Col>
              <div className="h-100">
                <Section
                  setParentDados={setParentDados}
                  parentDados={parentDados}
                  setUserDados={setUserDados}
                  userDados={userDados}
                />
                <Row className="row-cols-xxl-4 row-cols-lg-2 row-cols-1">
                  <Col className="d-flex flex-fill">
                    <Card className="card-body">
                      <div className="d-flex mb-4 align-items-center">
                        <div className="flex-shrink-0">
                          <FeatherIcon
                            icon="user"
                            className="avatar-sm rounded-circle text-success"
                          />
                        </div>
                        <div className="flex-grow-1 ms-2">
                          <h5 className="card-title mb-1">
                            Sua conta{" "}
                            {userDados?.painel_tipo === 1
                              ? "IPTV"
                              : userDados?.painel_tipo === 2
                              ? "P2P Plus"
                              : "P2P"}
                          </h5>
                          <p className="text-muted mb-0">
                            <b>Usuário:</b> {userDados?.username}
                          </p>
                        </div>
                      </div>
                      <h6 className="mb-1">
                        <b className="text-warning">Vencimento</b>:{" "}
                        {converterData(userDados?.expiracao)}
                      </h6>
                      <p className="card-text text-muted">
                        Faltam {contarDiasRestantes(userDados?.expiracao)} dias
                        para vencer
                      </p>
                      {parentDados &&
                      parentDados.id_cliente &&
                      parentDados.valor_usuario != 0 &&
                      parentDados.public_key !== "" ? (
                        <>
                          <Button
                            className="btn btn-primary btn-sm"
                            onClick={() =>
                              setModalMercadoPago(!modalMercadoPago)
                            }
                          >
                            Renovar Assinatura
                          </Button>
                          <ModalMercadoPago
                            modalMercadoPago={modalMercadoPago}
                            setModalMercadoPago={setModalMercadoPago}
                            parentDados={parentDados}
                            userDados={userDados}
                          />
                        </>
                      ) : (
                        <Button className="btn btn-dark btn-sm" disabled>
                          Auto Renovar Desabilitado
                        </Button>
                      )}
                    </Card>
                  </Col>

                  <Col className="d-flex">
                    <Card className="card-body flex-fill">
                      <div className="d-flex mb-4 align-items-center">
                        <div className="flex-shrink-0">
                          <FeatherIcon
                            icon="user"
                            className="avatar-sm rounded-circle text-primary"
                          />
                        </div>
                        <div className="flex-grow-1 ms-2">
                          <h5 className="card-title mb-1">Seu Revendedor</h5>
                          <p className="text-muted mb-0">
                            {userDados?.parent?.username}
                          </p>
                        </div>
                      </div>
                      <h6 className="mb-1">
                        <b>E-mail:</b> {userDados?.parent?.email}
                      </h6>
                      <p className="card-text text-muted">
                        {userDados?.parent?.email}
                      </p>

                      {userDados?.parent?.whatsapp &&
                        userDados?.parent?.whatsapp !== "" && (
                          <Link
                            to={`https://web.whatsapp.com/send?phone=${userDados?.parent?.whatsapp}`}
                            className="btn btn-success btn-sm"
                          >
                            WhatsApp
                          </Link>
                        )}

                      {userDados?.parent?.telegram &&
                        userDados?.parent?.whatsapp !== "" && (
                          <Link
                            to={`https://t.me/${userDados?.parent?.telegram}`}
                            className="btn btn-info btn-sm"
                          >
                            Telegram
                          </Link>
                        )}
                    </Card>
                  </Col>
                  <Col className="d-flex">
                    <Card className="card-secondary flex-fill">
                      <CardBody>
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0">
                            <img
                              src={hzplay}
                              alt=""
                              className="avatar-sm rounded-circle"
                            />
                          </div>
                          <div className="flex-grow-1 ms-3">
                            <h6 className="mb-1 fw-medium">Loja HZ Play</h6>
                            <p className="card-text">
                              <span className="fw-medium">
                                Aqui você encontra os aplicativos da HZ Play
                                para download.
                              </span>
                            </p>
                          </div>
                        </div>
                      </CardBody>
                      <div className="card-footer">
                        <div className="text-center">
                          <Link
                            to="https://loja.hzplay.fun/"
                            className="link-light"
                            target="_blank"
                          >
                            Acesse aqui{" "}
                            <i className="ri-arrow-right-s-line align-middle lh-1"></i>
                          </Link>
                        </div>
                      </div>
                    </Card>
                  </Col>
                  <Col className="d-flex">
                    <Card className="card-success flex-fill">
                      <CardBody>
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0">
                            <img
                              src={iptv}
                              alt=""
                              className="avatar-sm rounded-circle"
                            />
                          </div>
                          <div className="flex-grow-1 ms-3">
                            <h6 className="mb-1 fw-medium">
                              WebPlayer para IPTV
                            </h6>
                            <p className="card-text">
                              <span className="fw-medium">
                                Acesse o WebPlayer para assistir TV online
                                (apenas contas IPTV)
                              </span>
                            </p>
                          </div>
                        </div>
                      </CardBody>
                      <div className="card-footer">
                        <div className="text-center">
                          <Link
                            to="http://hzna.live/"
                            target="_blank"
                            className="link-light"
                          >
                            Acesse aqui{" "}
                            <i className="ri-arrow-right-s-line align-middle lh-1"></i>
                          </Link>
                        </div>
                      </div>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DashboardEcommerce;
